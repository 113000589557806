import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import Home from "./components/Home";
import ContactUs from "./components/Contact";
import Footer from "./components/Footer";
import { Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import { Helmet } from "react-helmet";
import Custom from "./components/Custom";
import Website from "./components/Website";
import Outsourcing from "./components/Outsourcing";
import About from "./components/About";
import Product from "./components/product";
import Hotelsof from "./components/Hotelsof";
import Hospital from "./components/Hospital";
import TeaSof from "./components/Teasof";
import JewSof from "./components/Jewsof";
import Pharma from "./components/Pharma";
import TeaSale from "./components/Teasale";
import Restaurant from "./components/Restaurant";
import Bakery from "./components/Bakery";
import Pathology from "./components/Pathology";
import Transport from "./components/transport";
import PaySof from "./components/paysof";
import NewsSof from "./components/NewsSof";
import EmailContactForm from "./components/EmailContactForm";
import Retails  from "./components/retail";

const App = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>EBS Software Solution's Official</title>
        <meta
          name="description"
          content="Software Development India, West Bengal, Kolkata, Siliguri | Software Company in India | Software Development West Bengal | Customised Software India | Software for Hotel, Medicine Distributors, Jewellery ShopReact application,Software Development India, West Bengal, Kolkata, Siliguri | Software Company in India | Software Development West Bengal | Customised Software India | Software for Hotel, Medicine Distributors, Jewellery Shop, Software Development Company, Web Development Company, Payment Gateway Integration Company in Siliguri, Kolkata, West Bengal, India,The most popular and promising softwaqre development company in India. Get our own software made with ease. We also have huge collection of ready-made softwares for you to choose from. On demand software customization. Full Customer Support and many more. Contact Us to get your demo for your dream software."
        />
      </Helmet>
      <Navbar />

      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Product" element={<Product />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/custom" element={<Custom />} />
          <Route path="/About" element={<About />} />
          <Route path="/website" element={<Website />} />
          <Route path="/outsourcing" element={<Outsourcing />} />
          <Route path="/Hotelsof" element={<Hotelsof />} />
          <Route path="/Hospital" element={<Hospital />} />
          <Route path="/teasof" element={<TeaSof />} />
          <Route path="/jewsof" element={<JewSof />} />
          <Route path="/pharma" element={<Pharma />} />
          <Route path="/teasale" element={<TeaSale />} />
          <Route path="/restaurant" element={<Restaurant />} />
          <Route path="/bakery" element={<Bakery />} />
          <Route path="/pathology" element={<Pathology />} />
          <Route path="/paysof" element={<PaySof />} />
          <Route path="/newssof" element={<NewsSof />} />
          <Route path="/transport" element={<Transport />} />
          <Route path="/EmailContactForm" element={<EmailContactForm />} />
          <Route path="/Retails" element={<Retails />} />
        </Routes>
      </div>

      <Footer />
    </>
  );
};

export default App;
