import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import web5 from "../img/hotelp.jpg";
import "../style/styles.css";
import { Helmet } from "react-helmet";
const Hotelsof = () => {
  let alttext = "Photo";
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          EBS Software Solution -Hotel Management System,Siliguri,West
          Bengal,India
        </title>
        <meta
          name="description"
          content="Hotel Management Software, West Bengal, Kolkata, Siliguri,Inida | Hospitality Software | Property Management System (PMS) | Hotel Booking Software | Reservation Management System, Front Desk Software,Hotel POS (Point of Sale) India, West Bengal, Guest Relationship Management (GRM) | Room Inventory Management | Hotel Operations Software | Revenue Management Software | Software for Hotel, Integrated Hotel Software, Cloud-based Hotel Software, Hotel CRM (Customer Relationship Management), Housekeeping Management System, Centralized Reservation System in Siliguri,Check-in/Check-out Software,Hotel Accounting Software"
        />
      </Helmet>
      <Container>
        <Row>
          <Col sm={12}>
            <img
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "50vh",
                width: "100%",
              }}
              className="responsive-image"
              src={web5}
              alt={alttext}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <br />
            <h2 style={{ fontWeight: "bold", textAlign: "center" }}>
              HotelSof
            </h2>
            <p
              style={{
                fontWeight: "bold",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              GST Enabled Hotel / Motel / Resorts Management System
            </p>
            <br />
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <p style={{ textAlign: "justify" }}>
              Leading the way in providing hotels, motels, and resorts with
              excellent Property Management Systems (PMS) is HotelSof. Our
              extensive software suite consists of modules that seamlessly
              integrate to address every aspect of hotel management. Being a
              well-known property management system in the field, HotelSof
              provides a variety of options designed to satisfy the various
              demands of our clientele.
            </p>
            <p
              style={{
                textAlign: "left",
                fontSize: "22px",
                fontWeight: "bold",
                color: "black",
              }}
            >
              Price in India : Rs. 65,000/- (Desktop Edition)+GST
            </p>
            <div className="container-container">
              <div className="row">
                <div className="card">
                  <div className="card-title">
                    <p style={{ fontWeight: "bold", color: "black" }}>
                      Key Features of HotelSof
                    </p>
                  </div>
                  <p>
                    <ol
                      style={{
                        textAlign: "justify",
                        marginRight: "5px",
                        marginBottom: "0rem",
                      }}
                    >
                      <ci>
                        HotelSof stands out with its holistic approach to hotel
                        management. Our software suite includes integrated
                        modules for Room Reservations, Food & Beverage Point of
                        Sale (POS), and complete accounting solutions. This
                        all-in-one package ensures that every aspect of your
                        hotel's operations is not just managed but optimized for
                        efficiency and excellence. Whether you run a small,
                        elegant hotel or a large, full-service property,
                        HotelSof is designed to scale according to your unique
                        requirements. Our flexible solutions accommodate the
                        distinct needs of different establishments, offering a
                        customizable and adaptable experience.
                      </ci>
                    </ol>
                  </p>
                  <br />
                  <p>
                    <ol
                      style={{
                        textAlign: "justify",
                        marginRight: "5px",
                        marginBottom: "0rem",
                      }}
                    >
                      <ci>
                        HotelSof makes your check-in and check-out quick and
                        trouble-free for individual and group hotel bookings.
                        Exclusive and Inclusive GST/Tax calculating flexiblity.
                        Define multiple rates to the corporate house, travel
                        agents and also plan wise.
                      </ci>
                    </ol>
                  </p>
                  <br />
                  <p>
                    <ol
                      style={{
                        textAlign: "justify",
                        marginRight: "5px",
                        marginBottom: "0rem",
                      }}
                    >
                      <ci>
                        HotelSof removes the pressure of your housekeeping staff
                        with one-click actions and view room statuses.
                      </ci>
                    </ol>
                  </p>
                  <br />
                  <p>
                    <ol
                      style={{
                        textAlign: "justify",
                        marginRight: "5px",
                        marginBottom: "0rem",
                      }}
                    >
                      <ci>
                        Touch POS and Android App can enabled for order
                        generation and billing from mobile and tablet. Complete
                        integration with Front Office.
                      </ci>
                    </ol>
                  </p>
                  <br />
                  <p>
                    <ol
                      style={{
                        textAlign: "justify",
                        marginRight: "5px",
                        marginBottom: "0rem",
                      }}
                    >
                      <ci>
                        HotelSof offers a solution for the complete inventory
                        management cycle starting from intend to purchase order,
                        approvals and GRN with provisions for online material
                        request and issues to facilitate paper less management
                        of inventory making it a unique hotel erp system.
                        Flexible grouping of items with multiple units and
                        conversion factor combined with multiple store options
                        ensure that you have precise control on the inventory.
                        Vendor management, purchase invoice and accounts
                        payable.
                      </ci>
                    </ol>
                  </p>
                  <br />
                  <p>
                    <ol
                      style={{
                        textAlign: "justify",
                        marginRight: "5px",
                        marginBottom: "0rem",
                      }}
                    >
                      <ci>
                        Excellent recipe management with detailed ingredients
                        costs. Costing options basis of transfer of stock.
                      </ci>
                    </ol>
                  </p>
                  <br />
                  <p>
                    <ol
                      style={{
                        textAlign: "justify",
                        marginRight: "5px",
                        marginBottom: "0rem",
                      }}
                    >
                      <ci>
                        HotelSof offers a complete financial accounting includes
                        cash/bank book, receivables and payables. And can
                        generated reports upto final accounting.
                      </ci>
                    </ol>
                  </p>
                  <br />
                  <p>
                    <ol
                      style={{
                        textAlign: "justify",
                        marginRight: "5px",
                        marginBottom: "0rem",
                      }}
                    >
                      <ci>
                        Guest SMS alerts for bookings confirmed, check-in and
                        checkout. Management SMS for various operations and
                        daily sales.
                      </ci>
                    </ol>
                  </p>
                  <br />
                  <p>
                    <ol
                      style={{
                        textAlign: "justify",
                        marginRight: "5px",
                        marginBottom: "0rem",
                      }}
                    >
                      <ci>
                        HotelSof define Banquet Halls, Hall Reservation,
                        Tentative Reservation, Multiple Dates Booking, Hourly
                        Availability Chart, Reservation Advance, Set Menu for
                        Function Booked, Additional Hall Booking, Banquet KOT,
                        Banquet Bills, Multiple Settlement Options.
                      </ci>
                    </ol>
                  </p>
                  <br />
                  <p>
                    <ol
                      style={{
                        textAlign: "justify",
                        marginRight: "5px",
                        marginBottom: "0rem",
                      }}
                    >
                      <ci>
                        This system allows direct billing for in-house
                        laundering as well as issue and return management from
                        vendors of third party laundering. Guest Laundry
                        Collection, Staff Chargeable Laundry, Express Laundry,
                        Direct Bill from Collection, Issue to vendor, Laundry
                        Gate Pass, Return from Vendor, Laundry Billing, Multiple
                        Settlement modes, Guest Laundry Rates, Vendor Rates.
                      </ci>
                    </ol>
                  </p>
                  <br />
                  <p>
                    <ol
                      style={{
                        textAlign: "justify",
                        marginRight: "5px",
                        marginBottom: "0rem",
                      }}
                    >
                      <ci    style={{
                        fontWeight: "Bold",
                      }}>
                      We have developed and introduced an innovative QR code-based menu system, allowing customers to conveniently place food orders directly from their tables or rooms using their smartphones. This system eliminates the need for physical menus or waitstaff interaction, enabling a contactless and seamless dining experience. Whether guests are dining in the restaurant or staying in a hotel room, they can easily browse the menu and place orders along with a digital ordering system through our Android app.
                      </ci>
                    </ol>
                  </p>
                  <br />
                  <p>
                    <ol
                      style={{
                        textAlign: "justify",
                        marginRight: "5px",
                        marginBottom: "0rem",
                      }}
                    >
                      <ci>
                        HotelSof HR Module offers flexible and easily
                        configurable features and facilities that makes it
                        user-friendly and accurate. It is also linked with the
                        financial accounting and other modules of HotelSof for
                        effective financial control and user access control. You
                        won’t need any complicated hardware for employess
                        attendance. HotelSof runs an Android App face
                        recognition attendance system, which provides
                        practically 100% robust and accurate attendance.
                      </ci>
                    </ol>
                  </p>
                </div>
              </div>
            </div>
            <br></br>
            {/* <p
              style={{
                textAlign: "left",
                marginRight: "5px",
                marginBottom: "0rem",
              }}
            >
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci style={{}}>Check In and Check Out Status</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci>Upgrade/Transfer Room (Check in)</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Revert Check In</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Room Reservation</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci>Tourist Agent Wise Reservation and Check in Status</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Check In Inventory View</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci>Room Reservation View (Monthly/Date Wise)</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci>Room Type Wise Occupancy Status</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci>Room Type Wise Availability Status</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci>Due Report Of Current Booking (Guest Ledger)</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci>Foreigner Certificate</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci>Police Report</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci>Room Sales Register</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Details Head wise Room Sales Register</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Guest History</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Company Wise Booking Status</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Night Audit Trial</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci>In House Laundry Managing</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Telephone Register (Date and Room Wise)</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Misc. Charges Register (Date and Room Wise)</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Guest Name Wise Booking Search</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Room Number Wise Booking Search</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Monthly Room Used Analysis</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Agent Wise Reservation Register</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Plan Wise Room Consumption (Graphical Analysis)</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Location Wise Room occupancy Status</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Car Rental</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Exclusive and Inclusive GST Calculation</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Room Salee GST type Wise</ci>
              </ol>
            </p>
            <p style={{ fontWeight: "bold", color: "black" }}>
              House Keeping (House Desk)
            </p>
            <p style={{ textAlign: "left", marginRight: "5px" }}>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci>Check In and Check Out Status</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci>Laundry Bill and Register</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Guest call Register</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Current Room Status</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Analysis of House Keeping Service</ci>
              </ol>
            </p>
          </Col>
          <Col lg={6} className="order-lg-last">
            <img
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "120vh",
                width: "80vh",
                marginRight: "20px",
              }}
              className="responsive-image"
              src={web5}
              alt="Right Image"
            /> */}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Hotelsof;
